@import url(/style/css/common.css);

.feedback[data-v-529ffdc2] {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.feedback .input-area[data-v-529ffdc2]{
  flex:1;
  border: 1px solid #eee;
}
.feedback .opt[data-v-529ffdc2]{
        justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    padding: 20px 0;
}


/*# sourceMappingURL=chunk-0ec7841c.a74d335d.css.map*/